import React, { useState, useEffect, useContext } from 'react';
import { Grid, Box } from '@mui/material';
import insta1 from '../../asstes/images/insta1.png'
import insta2 from '../../asstes/images/insta2.png'
import { makeStyles } from '@mui/styles';
import { Context as InstaContext } from "../../context/InstaContext";

const images = [
    insta1,
    insta2,
    insta2,
    insta1,
    insta2,
    insta2, insta1,
];

const useStyles = makeStyles({
    // background: {
    //     width: '100%',
    //     // height: '30vh',//100
    //     display: 'flex',
    //     overflow: 'hidden',
    // },
    background: {
        width: "100%",
        display: "flex",
        overflow: "hidden",
    },

});



const InstagramPost = () => {
    const classes = useStyles();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const { state: { InstaList, }, getAllInsta, } = useContext(InstaContext);

    useEffect(() => {
        getAllInsta()
    }, []);

    // const images = InstaList?.data?.map((item) => item.image) || [];
    const posts =
        InstaList?.data
            ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .slice(0, 7) || [];

    const rowHeight = 300;
    const rowHeight1 = 100;

    return (

        <Box
            sx={{
                flexGrow: 1,
                padding: 0,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                width: "100%",
                // width: screenWidth
            }}
            className={classes.background}
        >
            <Box sx={{ display: "flex", flexDirection: "raw", width: "100%" }}>
                {posts.length > 0 && (
                    <>
                        {/* First Image */}
                        <Grid sx={{ height: { xs: `${rowHeight1}px`, md: `${rowHeight}px` }, width: 2 * screenWidth / 5 }} >
                            <Grid item xs={12} sm={6} md={4}>
                                <a href={posts[0]?.inta_text} target="_blank" rel="noopener noreferrer">
                                    <Box
                                        component="img"
                                        src={posts[0]?.image}
                                        alt="Post 1"

                                        sx={{
                                            width: "100%",
                                            height: { xs: `${rowHeight1}px`, md: `${rowHeight}px` },
                                            objectFit: "cover",
                                            display: "block",
                                            // objectFit: "cover",
                                        }}
                                    />
                                </a>
                            </Grid>
                        </Grid>

                        {/* Second and Third Images */}
                        <Grid sx={{ height: { xs: `${rowHeight1}px`, md: `${rowHeight}px` }, width: screenWidth / 5 }} >
                            <Grid item xs={6}>
                                {posts[1] && (
                                    <a href={posts[1]?.inta_text} target="_blank" rel="noopener noreferrer">
                                        <Box
                                            component="img"
                                            src={posts[1]?.image}
                                            alt="Post 2"

                                            sx={{
                                                width: "100%",
                                                height: { xs: `${rowHeight1 / 2}px`, md: `${rowHeight / 2}px` },
                                                objectFit: "cover",
                                                display: "block",
                                            }}
                                        />
                                    </a>
                                )}
                                {posts[2] && (
                                    <a href={posts[2]?.inta_text} target="_blank" rel="noopener noreferrer">
                                        <Box
                                            component="img"
                                            src={posts[2]?.image}
                                            alt="Post 3"

                                            sx={{
                                                width: "100%",
                                                height: { xs: `${rowHeight1 / 2}px`, md: `${rowHeight / 2}px` },
                                                objectFit: "cover",
                                            }}
                                        />
                                    </a>
                                )}
                            </Grid>
                        </Grid>

                        {/* Fourth Image */}
                        <Grid sx={{ height: { xs: `${rowHeight1}px`, md: `${rowHeight}px` }, width: 2 * screenWidth / 5 }} >
                            <Grid item xs={12} sm={6} md={4}>
                                {posts[3] && (
                                    <a href={posts[3]?.inta_text} target="_blank" rel="noopener noreferrer">
                                        <Box
                                            component="img"
                                            src={posts[3]?.image}
                                            alt="Post 4"

                                            sx={{

                                                width: "100%",
                                                height: { xs: `${rowHeight1}px`, md: `${rowHeight}px` },
                                                objectFit: "cover",
                                                display: "block",
                                            }}
                                        />
                                    </a>
                                )}
                            </Grid>
                        </Grid>

                        {/* Fifth and Sixth Images */}
                        <Grid sx={{ height: { xs: `${rowHeight1}px`, md: `${rowHeight}px` }, width: screenWidth / 5 }} >
                            <Grid item xs={6}>
                                {posts[4] && (
                                    <a href={posts[4]?.inta_text} target="_blank" rel="noopener noreferrer">
                                        <Box
                                            component="img"
                                            src={posts[4]?.image}
                                            alt="Post 5"

                                            sx={{
                                                width: '100%',
                                                height: { xs: `${rowHeight1 / 2}px`, md: `${rowHeight / 2}px` },
                                                display: "block",
                                            }}
                                        />
                                    </a>
                                )}
                                {posts[5] && (
                                    <a href={posts[5]?.inta_text} target="_blank" rel="noopener noreferrer">
                                        <Box
                                            component="img"
                                            src={posts[5]?.image}
                                            alt="Post 6"

                                            sx={{
                                                width: '100%',
                                                height: { xs: `${rowHeight1 / 2}px`, md: `${rowHeight / 2}px` },
                                                display: "block",
                                            }}
                                        />
                                    </a>
                                )}
                            </Grid>
                        </Grid>

                        <Grid sx={{ height: { xs: `${rowHeight1}px`, md: `${rowHeight}px` }, width: 2 * screenWidth / 5 }} >
                            <Grid item xs={12} sm={6} md={4}>
                                <a href={posts[6]?.inta_text} target="_blank" rel="noopener noreferrer">
                                    <Box
                                        component="img"
                                        src={posts[6]?.image}
                                        alt="Post 6"
                                        sx={{
                                            width: "100%",
                                            height: { xs: `${rowHeight1}px`, md: `${rowHeight}px` },
                                            objectFit: "cover",
                                            display: "block",
                                            // objectFit: "cover",
                                        }}
                                    />
                                </a>
                            </Grid>
                        </Grid>

                    </>
                )}
            </Box>
        </Box >
    );
};

export default InstagramPost;
